export function getCookie(name: string, defaultValue: string | undefined) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"))
  if (match) {
    return match[2]
  } else {
    return defaultValue
  }
}

export function setCookie(name: string, value: string, expirationInDays: number) {
  const d = new Date()
  d.setTime(d.getTime() + expirationInDays * 24 * 60 * 60 * 1000)
  const expires = "expires=" + d.toUTCString()
  document.cookie = name + "=" + value + ";" + expires + ";path=/"
}

export function deleteCookie(name: string) {
  if (getCookie(name, undefined)) {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/"
  }
}
