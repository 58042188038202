<template>
  <div
    class="contents"
    :class="{ 'ui-blocked': blockUIState.UIIsBlocked }"
  >
    <RouterView />
    <div
      v-if="displayVersion === 'true'"
      class="absolute bottom-0 right-4 text-xxs text-grey-300"
    >
      {{ appVersion }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useBlockUIStore } from "@/stores/BlockUIStore"

  export interface IdCheck {
    id: string
    service: any
  }

  const blockUIState = useBlockUIStore()

  const displayVersion = import.meta.env.VITE_DISPLAY_APP_VERSION
  const appVersion = import.meta.env.VITE_APP_VERSION
</script>

<style lang="scss">
  @import "@/assets/css/vendor/kendo/main.scss";
</style>

<style lang="postcss">
  .ui-blocked {
    pointer-events: none !important;
  }
</style>
