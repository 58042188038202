import { ref } from "vue"
import { defineStore } from "pinia"

export const useOnboardingStore = defineStore("OnboardingStore", () => {
  const Guid = ref("")

  function setGuid(newValue: string) {
    Guid.value = newValue
  }
  function getGuid() {
    return Guid.value
  }

  return { Guid, getGuid, setGuid }
})
