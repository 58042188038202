import { ref } from "vue"
import { defineStore } from "pinia"

export const useBlockUIStore = defineStore("BlockUIStore", () => {
  const UIIsBlocked = ref(false)

  function blockUI() {
    UIIsBlocked.value = true
  }
  function unblockUI() {
    UIIsBlocked.value = false
  }

  return { UIIsBlocked, blockUI, unblockUI }
})
