import type { RouteLocationNormalized, Router } from "vue-router"
import { msalInstance, loginRequest } from "../modules/msal/msalAuthConfig"
import { InteractionType, type PopupRequest, type IPublicClientApplication, type RedirectRequest } from "@azure/msal-browser"
import { Role } from "@/modules/security/Role"

export function registerGuard(router: Router, accessDeniedRoute: string) {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    if (to.meta.requiresAuth) {
      const request = {
        ...loginRequest,
        redirectStartPage: to.fullPath,
      }
      const shouldProceed = (await isAuthenticated(msalInstance, InteractionType.Redirect, request)) && isInNotInMultipleBackOfficeRoles(msalInstance)
      return shouldProceed || accessDeniedRoute
    }

    return true
  })
}

export async function isAuthenticated(
  instance: IPublicClientApplication,
  interactionType: InteractionType,
  loginRequest: PopupRequest | RedirectRequest,
): Promise<boolean> {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance
    .handleRedirectPromise()
    .then(() => {
      const accounts = instance.getAllAccounts()
      if (accounts.length > 0) {
        return true
      }

      // User is not signed in and attempting to access protected route. Sign them in.
      if (interactionType === InteractionType.Popup) {
        return instance
          .loginPopup(loginRequest)
          .then(() => {
            return true
          })
          .catch(() => {
            return false
          })
      } else if (interactionType === InteractionType.Redirect) {
        return instance
          .loginRedirect(loginRequest)
          .then(() => {
            return true
          })
          .catch(() => {
            return false
          })
      }

      return false
    })
    .catch(() => {
      return false
    })
}
function isInNotInMultipleBackOfficeRoles(instance: IPublicClientApplication) {
  const account = instance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles

  if (account && userRoles) {
    const isTenantAdministrator = userRoles.includes(Role.TenantAdministrator)
    const isUser = userRoles.includes(Role.User)
    const isAdministrativeAgent = userRoles.includes(Role.AdministrativeAgent)

    const ta_u = isTenantAdministrator && isUser
    const ta_aa = isTenantAdministrator && isAdministrativeAgent
    const u_aa = isUser && isAdministrativeAgent
    return !(ta_u || ta_aa || u_aa)
  }
}
