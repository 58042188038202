import { loginRequest, msalInstance } from "../msal/msalAuthConfig"
import axios from "axios"
import { OpenAPI } from "../../oas/generated"
import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { useOnboardingStore } from "@/stores/OnboardingStore"
import { setCookie, deleteCookie } from "@/modules/common/AcCookies"
import { AcConstants } from "@/modules/common/AcConstants"
import { i18n } from "@/modules/i18n/i18nConfig"
import router from "@/router"
const t = i18n.global.t

OpenAPI.BASE = ""
//OpenAPI.BASE = import.meta.env.VITE_API_HOST_NAME
//OpenAPI.BASE = "http://localhost:3004"; // Used for mocking only

export function configureAxios() {
  axios.interceptors.request.use(async (config: any) => {
    const store = useOnboardingStore()
    const guid = store.getGuid()

    if (guid) {
      setCookie(AcConstants.cookieNameGuid, guid, AcConstants.cookieExpirationInDays)
    } else {
      deleteCookie(AcConstants.cookieNameGuid)

      try {
        const response = await msalInstance.acquireTokenSilent(loginRequest)
        config.headers["Authorization"] = `Bearer ${response.accessToken}`
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return msalInstance.acquireTokenRedirect(loginRequest)
        }
      }
    }
    return config
  })

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const excludedRoutes = ["home"]
      const isExcludedRoute = excludedRoutes.includes(router.currentRoute.value.name as string)

      if (error.response && error.response.status === 403 && !isExcludedRoute) {
        router.push({ name: "Error", query: { code: "403", text: t("general.accessDenied") } })
      }
      return Promise.reject(error)
    },
  )
}
