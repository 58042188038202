console.log("App is running in " + import.meta.env.MODE + " mode.")

import { createApp } from "vue"
import { createPinia } from "pinia"
import App from "@/App.vue"
import router from "@/router"
import { msalPlugin } from "@/plugins/msalPlugin"
import { configureMsal, msalInstance } from "@/modules/msal/msalAuthConfig"
import { configureAxios } from "@/modules/axios/axiosConfig"
import { i18n } from "@/modules/i18n/i18nConfig"
import AcErrorHandler from "./modules/common/AcErrorHandler"
import "@/assets/css/main.css"
import { AppInsightsPlugin } from "vue3-application-insights"
import { aiOptions } from "./modules/applicationinsights/aiOptions"

configureMsal()
configureAxios()

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(msalPlugin, msalInstance)
app.use(i18n)

app.config.errorHandler = (err) => AcErrorHandler.onError(err)

if (import.meta.env.VITE_USE_APPLICATION_INSIGHTS === "true") {
  app.use(AppInsightsPlugin, aiOptions)
}

app.mount("#app")
