import { useNotificationStore } from "@/stores/NotificationStore"

export default class AcErrorHandler {
  static onError(err: any) {
    useNotificationStore().openErrorNotification(err as string)
  }
}

export function getMessageFromErrorResponse(err: any) {
  let errorMsg = err.msg
  if (err.body.detail) {
    errorMsg = err.body.detail
  } else {
    errorMsg = err.statusText
  }
  return errorMsg
}
