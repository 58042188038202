import type { AppInsightsPluginOptions } from "vue3-application-insights"
import router from "@/router"

const connectionStringValue = import.meta.env.VITE_APP_INSIGHTS_CONNECTION_STRING
export const aiOptions: AppInsightsPluginOptions = {
  appName: "August Connect", // Prefix for route events
  router: router,
  trackAppErrors: true,
  appInsightsConfig: {
    config: {
      enableAjaxErrorStatusText: true,
      enableRequestHeaderTracking: true,
      enableUnhandledPromiseRejectionTracking: true,
      connectionString: connectionStringValue,
    },
  },
}

/*** IF we need some custom event to track - use it in code were it is needed:
      appInsights.trackEvent(
              {
                name: "example name",
              },
              {
                customProp1: "custom property test",
                error: err,
              }
            )
      */
