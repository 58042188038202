import { EventType, LogLevel, PublicClientApplication, type AuthenticationResult } from "@azure/msal-browser"

import router from "../../router"
import { MsalNavigationClient } from "../../router/MsalNavigationClient"

export const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_API_AZURE_CLIENT_ID,
    authority: import.meta.env.VITE_API_AZURE_AUTHORITY,
    redirectUri: import.meta.env.VITE_API_AZURE_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: import.meta.env.VITE_API_AZURE_LOGOUT_REDIRECT_URI, // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: "localStorage",
    claimsBasedCachingEnabled: true,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Error,
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
    },
  },
}

export const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig)

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [`api://${msalConfig.auth.clientId}/ReadProfile`],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
}

export function configureMsal() {
  // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigationClient = new MsalNavigationClient(router)
  msalInstance.setNavigationClient(navigationClient)

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account)
    }
  })
}
